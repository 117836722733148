@font-face {
font-family: '__sofiapro_77fdfd';
src: url(https://taxes-e8hqikak1.purposity.app/_next/static/media/2f3ffd6913e347e8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__sofiapro_Fallback_77fdfd';src: local("Arial");ascent-override: 67.81%;descent-override: 21.72%;line-gap-override: 5.41%;size-adjust: 105.45%
}.__className_77fdfd {font-family: '__sofiapro_77fdfd', '__sofiapro_Fallback_77fdfd';font-weight: 700;font-style: normal
}.__variable_77fdfd {--font-sofiapro: '__sofiapro_77fdfd', '__sofiapro_Fallback_77fdfd'
}

@font-face {
font-family: '__gilroy_3b61e9';
src: url(https://taxes-e8hqikak1.purposity.app/_next/static/media/4f9d35a96b77d8f8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gilroy_3b61e9';
src: url(https://taxes-e8hqikak1.purposity.app/_next/static/media/c2d4c9c2542b905c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gilroy_3b61e9';
src: url(https://taxes-e8hqikak1.purposity.app/_next/static/media/9cda8d25de73986b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__gilroy_Fallback_3b61e9';src: local("Arial");ascent-override: 88.14%;descent-override: 23.02%;line-gap-override: 0.00%;size-adjust: 104.36%
}.__className_3b61e9 {font-family: '__gilroy_3b61e9', '__gilroy_Fallback_3b61e9'
}.__variable_3b61e9 {--font-gilroy: '__gilroy_3b61e9', '__gilroy_Fallback_3b61e9'
}

@font-face {
font-family: '__sourcesanspro_40da59';
src: url(https://taxes-e8hqikak1.purposity.app/_next/static/media/10357e89acf108be-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sourcesanspro_40da59';
src: url(https://taxes-e8hqikak1.purposity.app/_next/static/media/a736140e6931627e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__sourcesanspro_40da59';
src: url(https://taxes-e8hqikak1.purposity.app/_next/static/media/1fd6def6d58799d2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__sourcesanspro_Fallback_40da59';src: local("Arial");ascent-override: 104.47%;descent-override: 28.98%;line-gap-override: 0.00%;size-adjust: 94.19%
}.__className_40da59 {font-family: '__sourcesanspro_40da59', '__sourcesanspro_Fallback_40da59'
}.__variable_40da59 {--font-sourcesanspro: '__sourcesanspro_40da59', '__sourcesanspro_Fallback_40da59'
}

